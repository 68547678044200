import React, { Component } from "react";
import api from "../../../Services/api";
import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  Base,
  Inst,
  Instrucoes,
  Button,
  Contador,
  Icon,
  Footer,
  Enunciado
} from "../../Assets/Assets";

import { Constantes } from "../../../Services/Constantes";
import Timer from "../../../Services/Timer";
import { verificarRespostas } from "../Utils/verificarRespostas";
import Loading from "../../../Services/Loading";
import { Person } from "./Assets";
import ErrorComponent from '../../../Services/ErrorComponent';
import {
  quantidadeEscolhas,
  definirEnunciados
} from "../Utils/Calculos";
import { trazFotoUser } from "./utils";

import ErrorBoundary from "../../../components/error-boundary";
import ErrorMessage from "../../../components/error-message";

import styled from "styled-components";

import {
  notifyError
} from "../../../Services/Notificacoes";
import Messages from "../../../static/messages";
import apiCalls from "../../../config/apiCalls";

import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';
import abridgedControl from "../../../utils/abridgedControl";
import { withRouter } from 'react-router'

import capitalizeFirstLetterEachWord from '../../../utils/capitalizeFirstLetterEachWord';


// Imagens
const spinner = Constantes.imagemSpinner;

// Constantes sociometria
const pesoQuestao = [4, 1, -2];
const template = 0;

const P = styled.p`
  font-size: 13px;
`;

const StyledRow = styled(Row)`
  width: 100%;

  @media (max-width: 530px) {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: auto !important;

      div.participants {
        width: 100%;
      }
  }
`

const SOCIOMETRY_QUESTIONS = {
  FIRST_QUESTION: 0,
  SECOND_QUESTION: 1,
  THIRD_QUESTION: 2
}

class Sociometria extends Component {
  state = {
    grupoSociometria: [],
    etapa: 0,
    check: "",
    modalConfirmacao: false,
    modalMensagem: false,
    modalInstrucoes: true,
    msgMpdal: "",
    peso: 0,
    loading: true,
    selecionados: [],
    respostas: [],
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    fotos: {},

    error: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10
  };

  escolhasQuantidade = [0, 0, 0];
  perguntas = [];
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  componentDidMount = async () => {
    try {
      // Ativando socket
      this.subscribePush();
      await this.recebendoInfo();

      api
      .get(
        `api/projects/rodadas/atualizar/${sessionStorage.getItem(
          "projeto"
        )}/${localStorage.getItem("dinamica")}/get-tempos`,
        {
          params: {
            userId: sessionStorage.getItem('user'),
            rodadaAtual: this.props.rodada,
            instrumento: Constantes.instrumentoSociometria,
          }
        }
      )
    } catch (error) {
      console.error(error);
      this.setState({
        error: true
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop");
    Constantes.io.off("Projeto:NovosTempos");
    Constantes.io.off("Auth:LogoutInstrumento");
  };

  hasFinishedActivity(answers) {
    if (answers.finalizado) return true;
    else if (
      answers.resposta && 
      answers.resposta[SOCIOMETRY_QUESTIONS.THIRD_QUESTION] &&
      answers.resposta[SOCIOMETRY_QUESTIONS.THIRD_QUESTION].participantes &&
      answers.resposta[SOCIOMETRY_QUESTIONS.THIRD_QUESTION].participantes.length) {

      return true;
    }
    else return false;
  }

  /**
   * carrega dados dos grupos
   * define quantidade de escolhas permitidas
   * carrega perguntas do instrumento
   * carrega as respostas previas do usuario
   * carrega os dados dos intrumentos da rodada
   * configura as variaveis de tempo
   * consulta o peso de cada participante
   * configura o enunciado
   * salva a resposta da primeira questao em branco
   * traz a foto do participante
   */
  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      const dinamicaId = sessionStorage.getItem("atividade");
    // Verificando grupo e salvando informações dos participantes
    await api
      .get(
        `api/projects/dinamicas/${dinamicaId}/grupos/${this.props.rodada}/${
        this.props.grupo
        }`
      )
      .then(async res => {
        // Verificando grupo
        let selecionados = [];

        const grupoSociometria = res.data;

        grupoSociometria.forEach(part => {
          if (part.id.toString() !== sessionStorage.getItem("user")) {
            selecionados.push({ id: part.id, selecionado: false });
          }
        });

        // Definindo a quantidade de escolhas permitidas
        this.escolhasQuantidade = quantidadeEscolhas(
          grupoSociometria.length + 1
        );
        // Recebendo instrumento e perguntas
        await api
          .get("api/instruments/5c1d349b67d1e50299796173")
          .then(async res => {
            const response = res;
            this.perguntas = response.data.data[template].atividades;
            const dinamicaId = sessionStorage.getItem("atividade");
            await verificarRespostas(
              sessionStorage.getItem("user"),
              Constantes.instrumentoSociometria,
              Constantes.moduloDinamicas,
              dinamicaId,
              this.props.rodada,
              -1,
              null,
              this.props.idInstrumento
            )
              .then(async res => {
                let respostaPrevia = res;
                await api
                  .get(`api/projects/${Constantes.moduloDinamicas}/projeto/${dinamicaId}/instrumento/${Constantes.instrumentoSociometria}`)
                  .then(async res => {
                    const projetoDinamica = res.data;

                    let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
                    let rodadas = dinamica.rodadas
                    let instrumento = rodadas[
                      this.props.rodada
                    ].instrumentos.find(
                      intrumento =>
                        intrumento.content === Constantes.instrumentoSociometria
                    );

                    // Inicializando tempo
                    let tempos = instrumento.tempos.individual;
                    this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
                    tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount;
                    if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;
                    // Verificando respostas prévia
                    let modalInstrucoes = false;
                    if (respostaPrevia) {
                      if (this.hasFinishedActivity(respostaPrevia)) {
                        await this.proximaAtividade();

                        let enunciado = definirEnunciados(
                          SOCIOMETRY_QUESTIONS.THIRD_QUESTION,
                          this.escolhasQuantidade
                        );
                        this.setState({
                          enunciado,
                          ...respostaPrevia,
                          grupoSociometria,
                          tempos,
                          selecionados,
                          modalInstrucoes,
                          loading: false,
                          etapa: SOCIOMETRY_QUESTIONS.THIRD_QUESTION,
                          respostas: respostaPrevia.resposta
                        });
                      } else {
                        // Atualizando respostas
                        let enunciado = definirEnunciados(
                          respostaPrevia.resposta.length,
                          this.escolhasQuantidade
                        );
                        this.setState({
                          enunciado,
                          ...respostaPrevia,
                          grupoSociometria,
                          tempos,
                          selecionados,
                          modalInstrucoes,
                          loading: false,
                          etapa: respostaPrevia.resposta.length,
                          respostas: respostaPrevia.resposta
                        });
                      }
                    } else {
                      // não foi encontrada resposta prévia. primeiro acesso ao instrumento.

                      modalInstrucoes = true;
                      let enunciado = definirEnunciados(
                        0,
                        this.escolhasQuantidade
                      );
                      let tempoAtual = tempos.tempoRestante;
                      const atualizadoEm = srvTime();
                      let resp = {
                        instrumentId: this.props.idInstrumento,
                        instrumento: Constantes.instrumentoSociometria,
                        resposta: [],
                        rodada: this.props.rodada,
                        tempos: { tempoAtual },
                        atualizadoEm,
                        finalizado: false
                      };

                      // grava resposta vazia antes de iniciar instrumento.
                      await api
                        .put(
                          `api/user/respostas/modulo/${
                          Constantes.moduloDinamicas
                          }`,
                          {
                            resp,
                            atividadeId: dinamicaId
                          }
                        )
                        .catch(async err => {
                          console.error(err);
                          let info = {
                            status: 1,
                            resp,
                            atividadeId: dinamicaId,
                            modulo: Constantes.moduloDinamicas
                          };
                        });

                      this.setState({
                        enunciado,
                        grupoSociometria,
                        tempos,
                        selecionados,
                        modalInstrucoes,
                        loading: false,
                        errorCount: 0
                      });
                    }

                    // traz as fotos dos participantes de forma assíncrona.
                    grupoSociometria.map(s => {
                      trazFotoUser(s.id, sessionStorage.getItem('projeto'), (foto) => {
                        let { fotos } = this.state

                        fotos[s.nome] = foto
                        this.setState({ fotos })
                        let campoFoto = document.getElementById(`fotode-${s.nome}`)
                        if (campoFoto) campoFoto.style.backgroundImage = foto ? 
                        `url('https://files-storage.s3.dualstack.us-east-1.amazonaws.com/${foto}')`
                        : `url('https://files-storage.s3.dualstack.us-east-1.amazonaws.com/avatar_default.png')`;
                      })
                    })
                  })
                  // .catch(err => {
                  //   console.error(err)
                  //   this.setState({
                  //     error: true
                  //   });
                  // });
              })
              // .catch(err => {
              //   console.error(err);
              //   this.setState({
              //     error: true
              //   });
              // });
          })
          // .catch(err => {
          //   console.error(err);
          //   this.setState({
          //     error: true
          //   });
          // });
      })
      // .catch(err => {
      //   console.error(err);
      //   this.setState({
      //     error: true
      //   });
      // });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
    
  };

  subscribePush = async () => {
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoSociometria && data.userId === sessionStorage.getItem('user')) {

        let tempos = data.tempos.individual
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })

    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoSociometria &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go()
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (
        data.instrumento === Constantes.instrumentoSociometria
      ) {
        let tempos = data.tempos.individual;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      }
    });
    Constantes.io.on("Auth:LogoutInstrumento", async userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else await this.fimAtividade();
      }
    });
  };

  gravaRespostas = async (retry = false) => {
    if (this.state.errorCount >= this.state.errorRetry) {
      notifyError("Não foi possível salvar a resposta. Por favor, tente novamente");
      return this.setState({ loading: false, loadingSpinner: false, modalConfirmacao: false, errorCount: 0 });
    }
    const { etapa, respostas } = this.state;
    if (etapa > SOCIOMETRY_QUESTIONS.THIRD_QUESTION) {
      return;
    }
    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };

    if (!retry) {
      var atualizadoEm = srvTime();
      let idSelecionados = [];
      this.state.selecionados.forEach(
        pessoa => pessoa.selecionado && idSelecionados.push(pessoa.id)
      );

      respostas.push({
        participantes: idSelecionados,
      });
    } else {
      var atualizadoEm = retry;
    }
    // Salvando resposta
    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta: respostas,
      instrumento: Constantes.instrumentoSociometria,
      rodada: this.props.rodada,
      atualizadoEm,
      tempos
    };
    const dinamicaId = sessionStorage.getItem("atividade");
    await api
      .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
        resp,
        atividadeId: dinamicaId
      })
      .then(async res => {
        if (etapa === SOCIOMETRY_QUESTIONS.THIRD_QUESTION) {
          this.state.tempos.tempoRestante > 0
            ? this.setState({
              pararTimer: true
            })
            : await this.fimAtividade();
        } else {
          //Define novo enunciado
          let enunciado = definirEnunciados(etapa + 1, this.escolhasQuantidade);

          // Realizar o uncheck dos checks
          let { selecionados } = this.state;
          for (
            var i = 0;
            i < document.getElementsByClassName("itensCheck").length;
            i++
          ) {
            document.getElementsByClassName("itensCheck")[i].checked = false;
            selecionados.forEach(
              participante => (participante.selecionado = false)
            );
          }
          this.setState(previousState => ({
            etapa: previousState.etapa + 1,
            enunciado,
            respostas,
            selecionados,
            modalConfirmacao: false,
            loadingSpinner: false
          }));
        }
      })
      .catch(async err => {
        console.error(err);
        this.setState({ errorCount: this.state.errorCount + 1 });
        setTimeout(() => this.gravaRespostas(retry), 1000);
      });
  };

  verifica = () => {
    let b = document.getElementById("button");
    if (
      this.state.selecionados.filter(participante => participante.selecionado)
        .length === this.escolhasQuantidade[this.state.etapa]
    ) {
      this.setState({ modalConfirmacao: true });
      b.blur();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else
      this.setState({
        modalMensagem: true,
        msgMpdal:
          "É necessário escolher " + this.escolhasQuantidade[this.state.etapa]
      });
  };

  atualizarSelecionados = (e, id) => {
    let { selecionados } = this.state;
    if (
      selecionados.filter(participante => participante.selecionado).length ===
      this.escolhasQuantidade[this.state.etapa] &&
      e.target.checked
    ) {
      this.setState({
        msgMpdal: "Escolha apenas " + this.escolhasQuantidade[this.state.etapa],
        modalMensagem: true
      });
      e.target.checked = false;
    } else {
      if (e.target.checked) {
        selecionados.find(
          participante => participante.id === id
        ).selecionado = true;
      } else {
        selecionados.find(
          participante => participante.id === id
        ).selecionado = false;
      }
      this.setState({ selecionados });
    }
  };

  trazUsers = (valor, index) => {
    let nome = capitalizeFirstLetterEachWord(valor.nome.toLowerCase());

    // let foto = this.state.fotos['nome'];
    const foto = valor.foto || 'avatar_default.png';

    if (
      sessionStorage.getItem("user") !== valor.id &&
      !(
        this.state.respostas[1] &&
        this.state.etapa === this.perguntas.length - 1 &&
        this.state.respostas[1].participantes.find(
          participante => participante === valor.id
        )
      )
    ) {
      return (
        <Person
          className="text-center"  
          background={`https://files-storage.s3.dualstack.us-east-1.amazonaws.com/${foto}`}
        >
          <div>
            <input
              id={"myCheckbox" + index}
              type="checkbox"
              className="itensCheck"
              value={nome}
              onClick={e => this.atualizarSelecionados(e, valor.id)}
            />
            <label htmlFor={"myCheckbox" + index}>
              <div id={`fotode-${nome}`} className="foto" alt={nome} />{" "}
            </label>{" "}
            <br />
            <p>{abridgedControl(nome, 15)}</p>
          </div>
        </Person>
      );
    } else {
      return <div style={{ display: "none" }} />;
    }
  };

  proximaAtividade = async () => {
    try {
      this.setState({ loading: true });
      await apiCalls.dinamica.saveInstrumentAnswer(
        Constantes.instrumentoSociometria,
        this.props.idInstrumento,
        Constantes.moduloDinamicas,
        sessionStorage.getItem("atividade"),
        this.props.rodada,
        sessionStorage.getItem("projeto")
      );
      await this.props.action();
      this.setState({ 
        // loading: false,
        modalConfirmacao: false,
        loadingSpinner: false,
      });


    } catch (error) {
      console.error(error);
      notifyError("Não foi possível finalizar o instrumento. Por favor, tente novamente");
      this.setState({ 
        loading: false,
        modalConfirmacao: false,
        loadingSpinner: false,
      });

    }
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  fimAtividade = async() => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
      await this.proximaAtividade();
    }
  };

  avaliarProxima = async () => {
    try {
      if (!this.onProximo) {
        this.onProximo = true;
        this.setState({ loadingSpinner: true });
        await this.gravaRespostas();
      }
      document.getElementsByTagName("body")[0].scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.setState({ errorCount: 0 });
    } catch (err) {
      console.error(err);
    }      
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };
  toggleInstrucoes = () => {
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };
  toggleMensagem = () => {
    this.setState(previousState => ({
      modalMensagem: !previousState.modalMensagem
    }));
  };

  render() {
    const { loading, error } = this.state;
    if (this.state.haveError) return <ErrorComponent />;
    if (loading) return <Loading />;

    if (error) return <ErrorMessage message={Messages.msgErrorLoadDados} />

    return (
      <ErrorBoundary
        errorComponent={() => <ErrorMessage message={Messages.msgErrorLoadDados} />}>
        <div className="text-left back" tabIndex="0">
          <Modal
            isOpen={this.state.modalInstrucoes}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes>
                <div>
                  <P>Você terá um total de {`${millisToMinutesAndSeconds(this.tempoTotal)}`} minutos para responder a 3 questões. Para tanto, veja o que é solicitado em cada questão e indique os participantes escolhidos para cada uma delas.</P>
                  <b>Como selecionar os participantes em cada questão</b><br />
                  <strong>Acesso com mouse</strong>
                  <P>Leve o mouse até o nome/foto do participante e clique sobre ele. O participante ficará destacado.</P>
                  <strong>Acesso com touch</strong>
                  <P>Encoste e retire o dedo na tela do seu aparelho sobre o nome/foto do participante. O participante ficará destacado.</P>
                  <P>Após enviar a atividade você não poderá voltar para ajustar.</P>
                </div>
              </Instrucoes>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.toggleInstrucoes}>
                  ok
              </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalMensagem}
            toggle={this.toggleMensagem}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleMensagem}>Sociometria</ModalHeader>
            <ModalBody>{this.state.msgMpdal}</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleMensagem}>
                OK
            </Button>{" "}
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.modalConfirmacao}
            toggle={this.toggleConfirmacao}
            className={this.props.className}
            onClosed={() => (this.onProximo = false)}
          >
            <ModalHeader toggle={this.toggleConfirmacao}>Sociometria</ModalHeader>
            <ModalBody>
              Se você estiver satisfeito com suas escolhas clique em prosseguir,
              se não clique em cancelar
          </ModalBody>
            <ModalFooter>
              {!this.state.loadingSpinner ? (
                <div>
                  <Button
                    color="success"
                    onClick={() => this.avaliarProxima()}
                    disabled={this.state.loadingSpinner}
                  >
                    Prosseguir
                </Button>{" "}
                  <Button
                    color="danger"
                    onClick={this.toggleConfirmacao}
                    disabled={this.state.loadingSpinner}
                  >
                    Cancelar
                </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
                  {
                    this.state.errorCount > 0 ?
                      <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                    : null
                  }
                </div>
                )}
            </ModalFooter>
          </Modal>
          <Base>
            <Container>
              <br />
              <br />
              <br />
              <Row>
                <Col>
                  <h1>Sociometria</h1>
                </Col>
                <Col className="text-right">
                  <Icon
                    src={require("../../Assets/_assets/doubt.png")}
                    alt="Instrucoes"
                  />
                  <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col sm="6">
                  <Contador
                    counter={
                      <Timer
                        intervalResetRequest={this.state.intervalResetRequest}
                        intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                        crescente={false}
                        tempo={this.state.tempos.tempoRestante}
                        resetAutomatico={false}
                        tempoAtualizado={this.tempoAtualizado}
                        pararTimer={this.state.pararTimer}
                        resetar={false}
                        tempoParado={this.fimAtividade}
                        id="tempo"
                      />
                    }
                    tempo={
                      100 * (this.state.tempos.tempoRestante / this.tempoTotal)
                    }
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Enunciado>
                {this.state.etapa + 1}
                {" - "}
                {this.state.enunciado}
              </Enunciado>
              <StyledRow className='items-responsive'>
                {this.state.grupoSociometria.map((gr, index) => (
                  <div className='participants' key={index}>{this.trazUsers(gr, index)}</div>
                ))}
              </StyledRow>
              <br />
              <br />
              <br />
              <br />
              <Row>
                <Col className="text-center">
                  <Button id="button" onClick={this.verifica}>
                    {this.state.etapa !== this.escolhasQuantidade.length &&
                      "Próximo"}
                    {this.state.etapa === this.escolhasQuantidade.length &&
                      "Enviar"}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Footer />
                </Col>
              </Row>
            </Container>
          </Base>
        </div>
      </ErrorBoundary>
    );
  }
}
export default withRouter(Sociometria);
