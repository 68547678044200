import React, { Component } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { FaCamera, FaUpload, FaTrashAlt, FaCheck } from 'react-icons/fa';
import {
  Col,
  Container,
  Card,
  CardBody,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../Services/Loading';
import { notifySuccess, notifyError } from '../../Services/Notificacoes';
import { Constantes } from '../../Services/Constantes';
//import Header from "./../Assets/header"

import {
  Header,
  Base,
  Nome,
  Footer,
  UserGreeting,
  UserFoto,
  User,
  Button,
  ButtonCancel
} from '../Assets/Assets';
import ReactCrop from '../../components/ReactCrop/index.jsx';
import api from '../../Services/api';
import { startPing } from './ping';
import apiCalls from '../../config/apiCalls';

import './styles.css'

const apiProjects = apiCalls.projects;

const spinner = Constantes.imagemSpinner;
// const deviceWidth =
//   window.innerWidth > 0 ? window.innerWidth : window.screen.width;
// const deviceHeight =
//   window.innerHeight > 0 ? window.innerHeight : window.screen.height;

const Instrucoes = styled.div`
  font-size: 14px;
  ul {
    font-size: 14px;
  }
  div {
    text-align: justify;
  }
  b {
    font-size: 16px;
  }
`;
const Text = styled.p`
  font-size: 16px;
`;
const InputHidden = styled.input`
  display: none;
`;
const LabelButton = styled.label`
  background: ${(props) => (props.yellow ? '#f0c219' : '#012a4a')};
  color: ${(props) => (props.yellow ? '#012a4a' : '#fff')};
  border: 0px;
  font-size: 12px;
  width: 200px;
  margin: 20px 0px 20px 0px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
`;
const Logout = styled.button`
  border: 0px;
  background: transparent !important;
  color: black !important;
  text-decoration: underline;
  padding: 0px;
  margin: 0px;
`;

const StyledRow = styled(Row)`
  width: 100%;

  @media (max-width: 767px) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 0px !important;
  }
`

const StyledFooter = styled.div`
  width: 100%;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);

  .mouse-hover {
    cursor: pointer;
  }

  .mouse-hover:hover {
    opacity: .5 !important;
  }
`

class Intro extends Component {
  state = {
    foto: '',
    videoStreamModal: false,
    width: 520,
    height: 0,
    streaming: false,
    stream: null,
    thereIsPhoto: false,
    vd: 'block',
    textoInicial: '',
    loading: true,
    isPostingPhoto: false,
    iniciando: false,
    uploadedPhoto: false,
    uploadedImgW: 200,
    uploadedImgH: 200,
    cropModal: {
      isOpen: false,
      type: ''
    },
    cropFileName: '',
    cropImg: null,
    crop: {
      unit: '%',
      width: 50,
      x: 25,
      y: 25,
      aspect: 1/1
    },
    avatarChanged: false,
    avatar: '',
    avatarUrl: '',
    isTakingPic: false,
    deleteImgModal: false,
  };

  // executar na tentativa de fechar a tab/dar reload
  // handleBeforeUnload = (e) => {
  //   e.preventDefault()

  //   return (e.returnValue = 'Are you sure you want to close?')
  // }

  componentDidMount() {
    const projectId = sessionStorage.getItem('projeto');
    const userId = sessionStorage.getItem('user');
    const atividadeId = sessionStorage.getItem('atividade');
    const modulo = sessionStorage.getItem('modulo');
    this.idUser = userId;
    this.idAtividade = atividadeId;
    this.nomeUser = sessionStorage.getItem("username");

    // Listen for orientation changes
    window.addEventListener(
      'orientationchange',
      this.handleScreenOrientationChange,
      false
    );

    if (modulo.toLowerCase().includes('test')) {
      if (sessionStorage.getItem('currentTestIndex')) sessionStorage.removeItem('currentTestIndex');

      this.setState({ loading: false, textoInicial: Constantes.textosTestDefault })
      return;
    }

    this.subscribePush();

    // window.addEventListener('beforeunload', this.handleBeforeUnload)
    // console.log('(componentDidMount) beforeUnload seted.')

    // carrega a foto do user
    api
      .get(`/api/user/usuario/${userId}/foto/${projectId}`)
      .then((resGet) => {
        const foto = resGet.data;
        if (foto) {
          // adicionando foto ao session storage
          sessionStorage.setItem('foto', foto);
          this.setState({ foto, thereIsPhoto: true });
        } else {
          sessionStorage.setItem('foto', '');
          // alert(user.erro)
        }

        // Textos da atividade
        apiProjects.getProvasText(projectId, modulo, atividadeId)
          .then((res) => {
            let { textos, textosTemplate } = res.data;

            // Pegando dinâmica
            /*let dinamica = projeto.modulos.dinamicas.find(
            dinam => dinam._id.toString() === dinamicaId
          );
          console.log("*** Dinamica", dinamica);*/

            modulo === Constantes.moduloProvas
              ? (textos.inicial = textosTemplate.inicial + textos.inicial)
              : modulo === Constantes.moduloDinamicas
                ? (textos.inicial =
                  Constantes.textosDinamicaDefault + textos.inicial)
                : (textos.inicial = textos.inicial);

            this.setState({
              userId,
              projectId,
              textoInicial: textos.inicial,
              loading: false
            });
          })
          .catch(err => {
            console.error(err);
          });
      })
      .catch((err) => console.error(err));

    // this.setState({ width: (deviceWidth / 3) * 2 });
  }

  /*
  handleScreenOrientationChange = () => {
    let { streaming, width, height } = this.state
    // Announce the new orientation number
    // alert(JSON.stringify(window.screen.orientation));
    if (!streaming) return

    let video = document.getElementById("video")
    if (window.screen.orientation === 0) {
      // width = (deviceWidth / 3) * 2;
      width = 320
      height = video.videoHeight / (video.videoWidth/width);
    } else {
      // width = (deviceWidth / 3) * 2;
      height = 320
      width = video.videoHeight / (video.videoWidth/width);
    }
    
    this.setState({
      width,
      height
    })
  }
  */

  sendUserInfo = (instrumentId, userId, userName) => {
    Constantes.io.emit('dinamica:atividade-id', instrumentId);
    Constantes.io.emit('atividade:send-mongo-id', userId);
    Constantes.io.emit('atividade:send-username', userName);
  }

  subscribePush = () => {
    const io = Constantes.io;
    startPing('INTRO');

    io.on("reconnect", () => {
      this.sendUserInfo(this.idAtividade, this.idUser, this.nomeUser);
    });

    io.on('id', () => {
      let id = sessionStorage.getItem('user');

      io.emit('id', { id });
    });
    io.on('dinamica:logout', async () => {
      console.info("deslogado");
      this.logout();
    });

    this.sendUserInfo(this.idAtividade, this.idUser, this.nomeUser);
  };
  start = () => {
    this.setState({
      iniciando: true
    });
    this.props.history.push('/iniciar');
  };

  logout = async () => {
    await api
      .post('api/auth/logout', { userId: sessionStorage.getItem('user') })
      .then((response) => {
        clearInterval(Constantes.pingServerInterval); // para de pingar ao server
      })
      .catch((err) => {
        if (err.response) console.log(err.response.data);
      });
    let projetoId = sessionStorage.getItem('projeto');
    sessionStorage.clear();
    this.props.history.push(`/projeto/${projetoId}`);
  };

  toggleModal = (modal) => {
    this.setState((prev) => ({ [modal]: !prev[modal] }));
  };
  openVideoStream = () => {
    let { streaming, width, height, thereIsPhoto } = this.state;
    let video = document.getElementById('video');
    let canvas = document.getElementById('canvas');
    let photo = document.getElementById('photo');

    if (thereIsPhoto) return;

    console.log('mediaDevices: ', navigator.mediaDevices);

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          video.srcObject = stream;
          this.setState({ stream });
          video.play();
        })
        .catch((err) => {
          notifyError('Por favor, verifique se a câmera está conectada e se o navegador tem permissão para acessa-la.');
          console.error('An error occurred: ' + err);
          this.setState({ streaming: false });
          //this.toggleModal('videoStreamModal');
        });

      video.addEventListener(
        'canplay',
        (e) => {
          if (!streaming) {
            height = video.videoHeight / (video.videoWidth / width);

            video.setAttribute('width', width);
            video.setAttribute('height', height);
            // canvas.setAttribute("width", width)
            // canvas.setAttribute("height", height)
            canvas.setAttribute('width', width);
            canvas.setAttribute(
              'height',
              height
            );

            streaming = true;
            this.clearPhoto(width, height);
            this.setState({ streaming, width, height });
          }
        },
        false
      );

      this.setState({ canvas, video, photo, streaming, isTakingPic: true });
    } else {

      this.setState({
        streaming: false
      });
    }
  };

  clearPhoto = (width, height) => {
    let canvas = document.getElementById('canvas');
    let photo = document.getElementById('photo');

    // clear photo
    var context = canvas && canvas.getContext('2d');
    if (context && !this.state.uploadedPhoto) {
      context.fillStyle = '#AAA';
      context.fillRect(0, 0, width, height);

      var data = canvas.toDataURL('image/png');
      photo.setAttribute('src', data);
    }
  };

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  captureFrame = async () => {
    let { width, height } = this.state;
    let video = document.getElementById('video');
    let canvas = document.getElementById('canvas');
    let photo = document.getElementById('photo');

    var context = canvas.getContext('2d');
    if (width && height) {
      
      // context.drawImage(video, 0, 0, width, height)
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      var dataUrl = canvas.toDataURL('image/png');
      const blob = this.dataURLtoBlob(dataUrl);

      photo.setAttribute('src', dataUrl);

      this.openCropModal(undefined, 'avatar', blob);

      this.setState({
        file: blob,
        thereIsPhoto: true,
        photo: {
          src: dataUrl
        }
      });

      // enviar socket de foto atualizada para o consultor
      // Constantes.io.emit('User:Selfie', { foto: data })
      // await api.post("/api/user/selfie", {
      //   foto: data,
      //   _id: sessionStorage.getItem("user")
      // })
    } else {
      this.clearphoto(width, height);
    }
  };

  takePicture(e) {
    e.preventDefault();
    this.captureFrame();
  }

  /**
   * 
   * @param {string} userId 
   * @param {string} projectId 
   * @param {File} file 
   * 
   * @returns  {Object} user
   */
  handleUploadUserPhoto = async (userId, projectId, file) => {
    try {
      const formData = new FormData();

      formData.append('foto', file, file.name ? file.name : `${userId}-selfie.png`);
      formData.append('projectId', projectId);

      const resp = await api.patch(`/api/user/${userId}/imagem/`, formData);

      const { user, msg } = resp.data;

      notifySuccess(msg);

      this.setState({ cropFileName: file.name ? file.name : `${userId}-selfie.png`, })

      return user;
    } catch (error) {
      throw new Error(error.erro ? error.erro : error.message)
    }


  }

  async salvarFoto(e,img) {
    try {
      if (e) e.preventDefault();
      const { userId, projectId, photo, uploadedPhoto, file } = this.state;

      const fileToUpload = img || file

      const user = await this.handleUploadUserPhoto(userId, projectId, fileToUpload);

      const { foto } = user.projetos.find(project => String(project._idProjeto) === String(projectId));

      this.setState({ avatarUrl: foto, foto, cropModal: { isOpen: false, type: '' } });
      sessionStorage.setItem('foto', foto);
      this.toggleModal('videoStreamModal');

    } catch (err) {
      notifyError('Não foi possível salvar a foto. Por favor, tente novamente');
    } finally {
      this.setState({ isPostingPhoto: false });
    }

  }

  handleFileChange = async (img, e) => {
    if (e) e.preventDefault();
    const file = img;

    await this.salvarFoto(e, img);

    if (this.state.cropModal.type === 'avatar') {
      this.setState({ avatar: file, avatarChanged: true, cropModal: false });
    }
  }

  closeVideoStream = () => {
    const { streaming, video, stream } = this.state;
    if (this.state.uploadedPhoto) this.setState({
      uploadedPhoto: false,
      photo: null,
      thereIsPhoto: !!this.state.foto,
      isTakingPic: false
    })
    if (!streaming) return;

    video.pause();
    video.srcObject = undefined;
    // video.src = ""
    stream.getTracks()[0].stop();
    console.log('video off');
    this.setState({
      streaming: false,
      video,
      stream: null,
      photo: null,
      // height: 0,
      thereIsPhoto: !!this.state.foto,
      isTakingPic: false
    });
  };
  componentWillUnmount = async () => {
    Constantes.io.off('Auth:LogoutInstrumento');
    this.sendUserInfo(null, null, null);
  };

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async loadImgFile(e) {
    try {
      e.persist();
      const eventImg = e.target.files[0];
      const data = await this.toBase64(e.target.files[0]);
      this.openCropModal(e, 'avatar');
      if (data) {
        this.setState({
          file: eventImg,
          photo: {
            src: data
          },
          thereIsPhoto: true,
          uploadedPhoto: true,
        });
      }
    } catch (err) {
      notifyError('Não foi possível ler os dados da imagem. Por favor, selecione uma nova imagem e tente novamente.');
    }
  }

  handleImgCrop = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          this.setState({ cropImg: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  openCropModal = (e, type, fileBlob) => {
    if (!e && fileBlob) {
      const objFile = {
        target: {
          files: [fileBlob]
        }
      }
      this.handleImgCrop(objFile);
        this.setState({
          cropModal: {
            isOpen: true,
            type
          }
        });
    } else {
      if (!e.target.files || !e.target.files.length) return;
  
      const file = e.target.files[0];
  
      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        const fileName = e.target.files[0].name;
  
        this.handleImgCrop(e);
        this.setState({
          cropFileName: fileName,
          cropModal: {
            isOpen: true,
            type
          }
        });
      } else {
        notifyError("O formato da avatar deve ser .png, .jpg ou .jpeg. Tente novamente.");
      }
    }
  

  }

  excludeAvatar = async () => {
    try {
      if (this.state.isPostingPhoto || this.state.isTakingPic) return;

      const user = sessionStorage.getItem('user');
      const project = sessionStorage.getItem('projeto');
  
      await api.delete(`/v3/delete-images/user-project?userId=${user}&projectId=${project}&propertyToDelete=foto`);

      sessionStorage.setItem('foto', '');
      this.setState({ foto: '', photo: null, thereIsPhoto: false, uploadedPhoto: false });
  
      notifySuccess('Foto excluída com sucesso');
  
      this.toggleDeleteImgModal();
      this.toggleModal('videoStreamModal');
    } catch (err) {
      console.error(err);
      notifyError('Não foi possível excluir foto. Por favor, tente novamente');
    }
  }

  toggleDeleteImgModal = () => {
    this.setState({ deleteImgModal: !this.state.deleteImgModal });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="text-left">
        <ToastContainer />
        <Modal
          toggle={this.toggleDeleteImgModal}
          isOpen={this.state.deleteImgModal}
        >
          <ModalHeader toggle={this.toggleDeleteImgModal}>
            Remover imagem
          </ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            Deseja remover essa imagem ?
          </ModalBody>
          <ModalFooter>
            <ButtonCancel style={{ marginRight: 10 }} width={'170px'} onClick={this.toggleDeleteImgModal}>Cancelar</ButtonCancel>
            <Button width={'170px'}  onClick={this.excludeAvatar}>Remover</Button>
          </ModalFooter>
        </Modal>
        <ReactCrop
          fileName={this.state.cropFileName}
          minWidth={60}
          circularCrop={true}
          showCropModal={this.state.cropModal.isOpen}
          src={this.state.cropImg}
          crop={this.state.crop}
          onCancelCrop={() =>
          this.setState({ cropModal: false, thereIsPhoto: false, uploadedPhoto: false })}
          onSubmitCrop={this.handleFileChange}
        />
        <Header className='intro-header' style={ sessionStorage.getItem('modulo').toLowerCase().includes('test') ? { minHeight: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center' } : {} } padding={sessionStorage.getItem('modulo').toLowerCase().includes('test') ? '0px' : '30px'}>
          {sessionStorage.getItem('modulo').toLowerCase().includes('test') ? <><br/><div style={{ marginLeft: 130 }} className='tests-labels tests-labels-intro'>
            <span className='preview-title' style={{ color: '#C4C4C4', fontWeight: 'bolder' }}>AMBIENTE DE TESTES</span> <br />
            <span className='preview-label'>ATENÇÃO! AS ATIVIDADES REALIZADAS NESTE AMBIENTE NÃO TEM PONTUAÇÃO REAL E NÃO SÃO VÁLIDAS NO SEU PROCESSO</span>
          </div></> : null}
          <img
            className='logo-div'
            style={sessionStorage.getItem('modulo').toLowerCase().includes('test') ? { marginTop: 60 } : {}}
            id="foto"
            alt="logo"
            src={require('../Assets/_assets/logoheader.png')}
          />
        </Header>
        <br />

        <br />
        <Base>
          <br />
          <br />

          <UserFoto
            className='user-greet-avatar'
            cursor='pointer'
            style={{ cursor: 'pointer' }}
            foto={
              this.state.foto ? 
              this.state.foto :
              ""              
            }
            onClick={() => {
              const moduleName = sessionStorage.getItem('modulo');
              if (moduleName.toLowerCase().includes('test')) return;
              this.toggleModal('videoStreamModal')
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <span>Clique na imagem para alterar</span>
          </div>

          <Modal
            className='user-avatar-modal'
            size="md"
            style={{ maxWidth: 600 }}
            isOpen={this.state.videoStreamModal}
            onOpened={() => this.openVideoStream()}
            onClosed={() => this.closeVideoStream()}
            toggle={() => this.toggleModal('videoStreamModal')}
          >
            <ModalHeader toggle={() => this.toggleModal('videoStreamModal')}>Adicionar foto</ModalHeader>
            <ModalBody className="text-center">
              <canvas
                id="canvas"
                style={{
                  display: 'none',
                  width: this.state.width,
                  height: this.state.height,
                  
                }}
              />
              <div className="camera">
                <video
                  id="video"
                  style={{
                    // width: this.state.width,
                    // height: this.state.height,
                    display: this.state.thereIsPhoto ? 'none' : 'block',
                    margin: '0 auto',
                    width: '100%'
                  }}
                >
                  Video stream not available.
                </video>
              </div>
              <div
                className="output"
                style={{ display: this.state.thereIsPhoto ? 'block' : 'none' }}
              >
                <img
                  src={(this.state.photo && this.state.photo.src) || (this.state.foto && `https://files-storage.s3.dualstack.us-east-1.amazonaws.com/${this.state.foto}`)}
                  id="photo"
                  className="imagemFoto"
                  style={this.state.uploadedPhoto ? { height: this.state.uploadedImgH,  } : this.state.foto && (!this.state.photo || !this.state.photo.src) ? { width: 370, height: 370 } : null}
                />
              </div>
              </ModalBody>
              <StyledFooter>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} className="text-center">
                  {this.state.thereIsPhoto ? (
                    <Tooltip
                      title={ 'Alterar foto' }
                      position="bottom"
                      arrow="true"
                      size="small"
                    >
                      <FaCamera
                        id="startbutton"
                        className={'mouse-hover'}
                        onClick={(e) => {
                          this.setState({ thereIsPhoto: false, uploadedPhoto: false, isTakingPic: true });
                          setTimeout(() => {
                            this.openVideoStream();
                          }, 500);
                        }}
                        style={{marginRight: 14}}
                        disabled={this.state.isPostingPhoto}
                        color={'#012a4a'}
                        size={26}
                      />
                    </Tooltip>
                  ) : (
                      <>
                        <Tooltip
                          title={ 'Tirar foto' }
                          position="bottom"
                          arrow="true"
                          size="small"
                        >
                          <FaCamera 
                            id="startbutton"
                            className={'mouse-hover'}
                            onClick={(e) => this.takePicture(e)}
                            disabled={this.state.isPostingPhoto || !this.state.streaming}
                            style={{ marginLeft: 10, opacity: this.state.isPostingPhoto || !this.state.streaming ? '.3' : '100%' }} 
                            color={'#012a4a'}
                            size={26}
                          />
                        </Tooltip>
                        <label style={{ marginLeft: 25 }}>
                          <Tooltip
                            title={ 'Adicionar foto' }
                            position="bottom"
                            arrow="true"
                            size="small"
                          >
                            <FaUpload 
                              className={'mouse-hover'}
                              color={'#012a4a'}
                              style={{ marginRight: 14 }}
                              size={25} 
                            />
                          </Tooltip>
                          <InputHidden type="file" accept='.png, .jpg, .jpeg' onChange={e => this.loadImgFile(e)} />
                        </label>
                      </>
                    )}{' '}
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} className="text-center">
                    <Tooltip
                        title={ 'Excluir foto' }
                        position="bottom"
                        arrow="true"
                        size="small"
                    >
                      <FaTrashAlt
                        onClick={this.toggleDeleteImgModal}
                        className={'mouse-hover'}
                        disabled={ this.state.isPostingPhoto || this.state.isTakingPic }
                        style={{ opacity: this.state.isPostingPhoto || this.state.isTakingPic ? '.3' : '100%' }}
                        color={'#012a4a'}
                        size={23}
                      />
                    </Tooltip>
                  </div>  
                  </div>
              </StyledFooter>
          </Modal>
          <br />
          <UserGreeting>
            <div>
              Bem Vindo(a),
              <Nome> {sessionStorage.getItem('username')}</Nome>
             {sessionStorage.getItem('modulo').includes('test') ? null : <small>Não é Você?</small>}
              <br />
              <small>
                <Logout onClick={this.logout}>Fazer Logout</Logout>{' '}
              </small>
              <br />
              <br />
              <Instrucoes>
                <StyledRow>
                  <Col sm="6" className="offset-md-3">
                    <div
                      className="text-left"
                      dangerouslySetInnerHTML={{
                        __html: `${this.state.textoInicial}`
                      }}
                    />
                  </Col>
                </StyledRow>
                <br />
                <Container>
                  <Row>
                    <Col sm={{ size: 8, offset: 2 }}>
                      <Text />
                    </Col>
                  </Row>
                </Container>
              </Instrucoes>
              <Button onClick={this.start}>Iniciar</Button>
              <Footer />
              <br />
              <br />
            </div>
          </UserGreeting>
        </Base>
        {/* <Header onLogout={this.logout} />
        <Container>
          {" "}
          <br />
          <br />
          <br />
          <br />
          <Card>
            <CardBody>
              <Col sm="12" md={{ size: 12 }}>
                <h2>{sessionStorage.getItem("username")}</h2>
                <p>Seja Bem Vindo.</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mauris pulvinar varius purus sed lacinia. Integer eu lobortis
                  orci, in venenatis lorem. Mauris sed dapibus libero. Donec
                  posuere lorem ultrices magna volutpat tristique. Donec laoreet
                  odio ligula, ac interdum ante luctus eget. Fusce porta
                  pharetra velit, eu molestie lectus varius eget. Nulla in
                  malesuada enim. Aenean rhoncus nec sem at sagittis. Donec
                  consequat justo sed felis condimentum pellentesque. Sed
                  lobortis sem sed tempor consequat. Donec ut ipsum massa.
                  <br />
                  <br />
                  Donec et lacinia velit. Praesent ex arcu, aliquam sit amet
                  urna vel, iaculis tempus nisi. Nunc egestas euismod vehicula.
                  Pellentesque nibh tortor, porttitor eget tortor nec,
                  ullamcorper lobortis dui. Donec malesuada porta sem at
                  hendrerit. Pellentesque habitant morbi tristique senectus et
                  netus et malesuada fames ac turpis egestas. Etiam tincidunt
                  felis ac posuere facilisis. Sed condimentum eros tempor porta
                  iaculis. Ut cursus, neque ut faucibus bibendum, lorem urna
                  molestie arcu, vestibulum tempus lacus velit a ligula.
                </p>
                <Button
                  color="success"
                  className="pull-right"
                  onClick={this.start}
                >
                  Iniciar
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Container> */}
      </div>
    );
  }
}

export default Intro;
